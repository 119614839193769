import { ClaimVoucherControllerApi } from "@soctrip/axios-promotion-service"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import ImgCustom from "src/components/img-custom"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { useToast } from "src/hooks/use-toast"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { mathRoundNumber } from "src/utils/common"
import { convertCurrency } from "src/utils/convert-currency"
import { DealVoucherType } from "../types"

const DealVoucher = (props: DealVoucherType) => {
  const {
    id,
    voucherImg,
    reward,
    voucherName,
    minSpend,
    isClamp,
    updateClaimData,
    isRoom,
    discountType,
    isShopOwner,
    isDisplay,
  } = props
  const { t } = useTranslation()
  const authStore = useSelector((state: RootState) => state.auth)
  const showToast = useToast()

  const handleSave = () => {
    if (authStore?.value) {
      //todo
      new ClaimVoucherControllerApi(configHeader(SERVICE.PROMOTION))
        .createNewOne2({ voucher_id: id })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          if (res?.data?.data) {
            if (updateClaimData) updateClaimData(id)
            showToast({
              detail: capitalizedFirst(t("live-stream.claim-voucher-success")),
              severity: "success",
            })
          }
        })
        .catch(() => {
          showToast({
            detail: capitalizedFirst(t("live-stream.claim-voucher-failed")),
            severity: "error",
          })
        })
    } else {
      showToast({ detail: "Please login before continue", severity: "warn" })
    }
  }

  const getValueReward = useMemo(() => {
    if(discountType === "PERCENTAGE") {
      return `${mathRoundNumber(reward)}%`
    } else {
      return convertCurrency(reward)
    }
  }, [])
  if (isRoom && !isDisplay) return null
  return (
    <div className="flex w-full rounded-3 border border-orange-dark-300 bg-white">
      <div className="flex items-center justify-center px-[20px] py-[22px]">
        <div className="h-[48px] w-[48px]">
          <ImgCustom
            alt="voucherImg"
            url={voucherImg}
            className="h-full w-full"
          />
        </div>
      </div>
      <div className="my-3 border-r border-dashed border-orange-dark-300"></div>
      <div className="flex flex-1 gap-1 p-3">
        <div className="flex flex-1 flex-col">
          <span className="text-[14px] font-semibold leading-[20px] text-gray-700 line-clamp-2">
            {`${capitalizedFirst(t("live-stream.reward", { value: getValueReward }))}`}
          </span>
          <span className="mt-[2px] text-[14px] leading-[20px] text-gray-500 line-clamp-1">
            {voucherName}
          </span>
          <span className="mt-[2px] text-[12px] font-medium leading-[16px] text-gray-700 ">
            {capitalizedFirst(
              t("live-stream.min-spend", {
                value: convertCurrency(minSpend || 0),
              })
            )}
          </span>
        </div>
        <div className="flex flex-col items-center justify-center">
          {
            !isShopOwner ? (
              <button
                disabled={isClamp}
                onClick={handleSave}
                className={`flex items-center justify-center rounded-3 bg-blue-600 px-[12px] py-[4px] text-[14px] font-semibold leading-[16px] text-white hover:bg-blue-500  ${
                  isClamp ? "opacity-50" : ""
                }`}
              >
                {isClamp
                  ? capitalizedFirst(t("live-stream.saved"))
                  : capitalizedFirst(t("live-stream.save"))}
              </button>
            ) : null
          }
        </div>
      </div>
    </div>
  )
}

export default DealVoucher
