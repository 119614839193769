import React, { useEffect, useMemo, useState } from "react"
import { AddressType, AddressViewTypes } from "../type"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { Button } from "primereact/button"
import {
  CreateNewAddressModal,
  MyAddressModal,
  EditAddressModal,
  ConfirmModal,
} from "../../modal"
import { addressService } from "../../../services"
import AddressInformationView from "./address-information-view"
import { ORDER_STEP_VIEW } from "src/constants/common"
import { useToast } from "src/hooks/use-toast"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"

export default function DeliveryAddressView(props: AddressViewTypes) {
  const { updateAddressSelected, stepView } = props
  const [addressDefault, setAddressDefault] = useState<AddressType>()
  const [isOpenCreateNewAddressModal, setIsOpenCreateNewAddressModal] =
    useState<boolean>(false)
  const [isOpenMyAddressModal, setIsOpenMyAddressModal] =
    useState<boolean>(false)
  const [isConfirmDeleteAddress, setIsConfirmDeleteAddress] =
    useState<{
      isShow: boolean,
      addressId: string
    }>({isShow: false, addressId: ""})
  const [editAddressModal, setEditAddressModal] = useState<{
    isShow: boolean
    addressId: string
  }>({ isShow: false, addressId: "" })
  const { t } = useTranslation()
  const showToast = useToast()
  const shoppingCartStore = useSelector(
    (state: RootState) => state.shoppingCart
  )
  useEffect(() => {
    getDefaultAddress()
  }, [])

  async function getDefaultAddress() {
    await addressService.getAddressDefault().then((res) => {
      if (res.data.data) {
        const result = {
          id: res.data.data.id,
          fullName: res.data.data.user_full_name,
          phoneNumber: res.data.data.user_phone_number,
          address: res.data.data.address,
          country: res.data.data.country,
          city: res.data.data.city,
          district: res.data.data.district,
          ward: res.data.data.ward,
          addressType: res.data.data.address_type,
          isDefault: res.data.data.is_default,
          city_id: res.data.data.city_id || "",
          district_id: res.data.data.district_id || "",
          ward_id: res.data.data.ward_id || "",
        }
        setAddressDefault(result)
        updateAddressSelected(result)
      }
    })
  }
  const handleChangeAddress = () => {
    setIsOpenMyAddressModal(true)
  }

  const handleConfirmMyAddressModal = async (addressSelected: AddressType) => {
    updateAddressSelected(addressSelected)
    setAddressDefault(addressSelected)
    setIsOpenMyAddressModal(false)
  }

  const handleEditMyAddressSelect = async (addressEdit: string) => {
    setIsOpenMyAddressModal(false)
    setEditAddressModal({
      isShow: true,
      addressId: addressEdit,
    })
  }

  const handleOpenNewAddress = () => {
    setIsOpenMyAddressModal(false)
    setTimeout(() => {
      setIsOpenCreateNewAddressModal(true)
    }, 200)
  }

  const handleChangeDefaultAddress = (addressData: AddressType) => {
    addressService
      .updateAddress({
        userFullName: addressData.fullName,
        userPhoneNumber: addressData.phoneNumber,
        address: addressData.address,
        country: addressData.country,
        city: addressData.city,
        district: addressData.district,
        ward: addressData.ward,
        addressType: addressData.addressType,
        isDefault: addressData.isDefault,
      })
      .then(() => {
        addressService.getAddressDefault().then((res) => {
          if (res.data.data) {
            const result = {
              id: res.data.data.id,
              fullName: res.data.data.user_full_name,
              phoneNumber: res.data.data.user_phone_number,
              address: res.data.data.address,
              country: res.data.data.country,
              city: res.data.data.city,
              district: res.data.data.district,
              ward: res.data.data.ward,
              addressType: res.data.data.address_type,
              isDefault: res.data.data.is_default,
              city_id: res.data.data.city_id || "",
              district_id: res.data.data.district_id || "",
              ward_id: res.data.data.ward_id || "",
            }
            setAddressDefault(result)
            updateAddressSelected(result)
          }
        })
      })
  }

  const handleConfirmEditAddress = (isSelected: boolean) => {
    if (isSelected) {
      addressService.getAddressDefault().then(() => {
        setEditAddressModal({ isShow: false, addressId: "" })
        setTimeout(() => {
          setIsOpenMyAddressModal(true)
        }, 200)
      })
    } else {
      setEditAddressModal({ isShow: false, addressId: "" })
      setTimeout(() => {
        setIsOpenMyAddressModal(true)
      }, 200)
    }
  }
  const handleCloseEditAddressModal = () => {
    setEditAddressModal({ isShow: false, addressId: "" })
    setTimeout(() => {
      setIsOpenMyAddressModal(true)
    }, 200)
  }
  const handleCloseCreateNewAddressModal = async () => {
    await getDefaultAddress()
    setTimeout(() => { 
      setIsOpenCreateNewAddressModal(false)
    }, 200)
  }
  const handleCancelDelete = () => {
    setIsConfirmDeleteAddress({
      isShow: false,
      addressId: "",
    })
    setTimeout(() => {
      setIsOpenMyAddressModal(true)
    }, 200)
  }
  const handleOpenDelete = (addressId: string) => {
    setIsOpenMyAddressModal(false)
    setTimeout(() => {
      setIsConfirmDeleteAddress({
        isShow: true,
        addressId: addressId,
      })
    }, 300)
  }
  const handleConfirmDelete = () => {
    addressService.deleteAddress(isConfirmDeleteAddress.addressId).then(() => {
      handleCancelDelete()
    }).catch((err) => {
      const message = err?.response?.data?.error?.message  || "Address service not available"
      showToast({ detail: message, severity: "error" })
      handleCancelDelete()
    })
  }
  const isOnlyProductIsTicket = useMemo(() => {
    let result = false
    let prodSelect = 0
    let prodIsTicket = 0
    shoppingCartStore?.cartData.forEach(cart => {
      cart.objectDto.forEach(prod => {
        if(prod.isSelected) {
          prodSelect+=1
          if(prod.catalogs?.length && prod.catalogs[0].type === "E_TICKET") prodIsTicket +=1
        }
      })
    })
    if(prodSelect && prodIsTicket &&  prodSelect === prodIsTicket) result = true
    return result
  }, [shoppingCartStore])
  if(isOnlyProductIsTicket) return null
  return (
    <>
      <div className="flex flex-col rounded-3 border border-gray-200 bg-white p-3">
        <div className="flex items-center justify-between">
          <span className="text-20 font-semibold leading-30 text-gray-800">
            {capitalizedFirst(t("checkoutPage.deliveryAddress"))}
          </span>
          {addressDefault && stepView !== ORDER_STEP_VIEW.PAYMENT ? (
            <span
              onClick={handleChangeAddress}
              className="cursor-pointer text-14 font-semibold leading-20 text-blue-600"
            >
              {capitalizedFirst(t("global.change"))}
            </span>
          ) : null}
        </div>
        <div className="mt-1 flex">
          {addressDefault ? (
            <AddressInformationView {...addressDefault} />
          ) : (
            <Button
              onClick={() => setIsOpenCreateNewAddressModal(true)}
              className="flex w-[100%] items-center justify-center rounded-3 border border-dashed border-gray-100 bg-gray-50 text-18 font-semibold leading-28 text-gray-800"
            >
              <i className="pi pi-plus" />
              <span className="ml-1">
                {capitalizedFirst(t("checkoutPage.addAddress"))}
              </span>
            </Button>
          )}
        </div>
      </div>
      {isOpenCreateNewAddressModal && (
        <CreateNewAddressModal
          isShow={isOpenCreateNewAddressModal}
          handleClose={handleCloseCreateNewAddressModal}
          handleConfirm={handleCloseCreateNewAddressModal}
        />
      )}
      {isOpenMyAddressModal && (
        <MyAddressModal
          addressSelect={addressDefault}
          isShow={isOpenMyAddressModal}
          handleClose={() => setIsOpenMyAddressModal(false)}
          handleConfirm={handleConfirmMyAddressModal}
          handleEditMyAddressSelect={handleEditMyAddressSelect}
          openNewAddress={handleOpenNewAddress}
          handleChangeDefaultAddress={handleChangeDefaultAddress}
          openConfirmDeleteModal={(addressId: string) => handleOpenDelete(addressId)}
        />
      )}
      {editAddressModal.isShow && (
        <EditAddressModal
          isSelected={addressDefault?.id === editAddressModal.addressId}
          isShow={editAddressModal.isShow}
          addressId={editAddressModal.addressId}
          handleClose={handleCloseEditAddressModal}
          handleConfirm={handleConfirmEditAddress}
        />
      )}
      {isConfirmDeleteAddress.isShow && (
        <ConfirmModal
          isShow={isConfirmDeleteAddress.isShow}
          title={capitalizedFirst(t("modal.confirmModal"))}
          message={capitalizedFirst(t("modal.confirmAddressMessage"))}
          confirmLabel={"global.delete"}
          handleClose={handleCancelDelete}
          handleConfirm={handleConfirmDelete}
        />
      )}
    </>
  )
}
