import React, { useEffect, useMemo, useState } from "react"
import { t } from "i18next"
import { Dialog } from "primereact/dialog"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import Loading from "src/components/loading"
import EmptyPage from "src/pages/empty-page"
import { LivestreamCampaignControllerApi } from "@soctrip/axios-livestream-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
// import DealProduct from "../deal-product"
import DealProductModal from "../deal-product-modal"
import DealVoucher from "../deal-voucher"
import { DealProductModalType } from "../types"
import { ClaimVoucherControllerApi } from "@soctrip/axios-promotion-service"
import { handlePromiseAll } from "src/utils/common"
import { Badge } from "primereact/badge"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"

export interface ModalDataType {
  isShowModal: boolean
  handleClose: () => void
  handleConfirm: () => void
  id: string
  isShopOwner: boolean
}
export const TAB_STATE = {
  PRODUCT: "PRODUCT",
  VOUCHER: "VOUCHER",
}
export const STATE_VIEW = {
  INIT: "INIT",
  LOADING: "LOADING",
  NOT_FOUND: "NOT_FOUND",
  RENDER: "RENDER",
}

export interface DealVoucherType {
  id: string
  voucherImg: string
  reward: number
  voucherName: string
  minSpend: number
  discountType: string
}
export default function ModalData(props: ModalDataType) {
  const { handleClose, isShowModal, id, isShopOwner } = props
  const [tab, setTab] = useState<string>(TAB_STATE.PRODUCT)
  const [stateView, setStateView] = useState<string>(STATE_VIEW.INIT)
  const [products, setProduct] = useState<DealProductModalType[]>([])
  const [vouchers, setVouchers] = useState<DealVoucherType[]>([])
  const [clampVouchers, setClampVoucher] = useState<string[]>([])
  const authStore = useSelector((state: RootState) => state.auth)
  const shoppingCartStore = useSelector(
    (state: RootState) => state.shoppingCart
  )
  const handleChangeTab = (tabValue: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const apis: any[] = []
    if (tabValue === TAB_STATE.PRODUCT) {
      apis.push(getProductData())
    } else {
      apis.push(getVoucherData())
      apis.push(getClampVoucherData())
    }
    handlePromiseAll(apis).then(() => {
      setTab(tabValue)
    })
  }
  const gotoCart = () => {
    const url = `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/order-cart`
    window.open(url, "_blank")
  }
  const countCart = useMemo(() => {
    if (authStore.value) {
      return shoppingCartStore.totalCart
    }
    const result = 0
    return result
  }, [shoppingCartStore, authStore.value])
  const FooterContent = (
    <div className="flex justify-end pt-4 text-16 font-semibold leading-24">
      <div className="flex items-center">
        <button
          className="rounded-3 border border-gray-300  py-10px px-18px text-gray-700 hover:bg-gray-100"
          onClick={() => handleClose()}
        >
          {capitalizedFirst(t("global.cancel"))}
        </button>
      </div>
    </div>
  )
  const HeaderContent = (
    <div className="flex flex-col">
      <span className="text-[18px] font-semibold leading-[28px] text-gray-900">
        {capitalizedFirst(t("live-stream.store-modal"))}
      </span>
      <div className="mt-4 flex gap-1 text-[14px] font-semibold leading-[20px] ">
        <div
          onClick={() => handleChangeTab(TAB_STATE.PRODUCT)}
          className={`flex cursor-pointer items-center justify-center px-[12px] py-[8px] ${
            tab === TAB_STATE.PRODUCT
              ? "border-b-[2px] border-blue-600 text-blue-600"
              : "border-b-[1px] border-gray-300 text-gray-500"
          }`}
        >
          {capitalizedFirst(t("live-stream.product"))}
        </div>
        <div
          onClick={() => handleChangeTab(TAB_STATE.VOUCHER)}
          className={`flex cursor-pointer items-center justify-center px-[12px] py-[8px] ${
            tab === TAB_STATE.VOUCHER
              ? "border-b-[2px] border-blue-600 text-blue-600"
              : "border-b-[1px] border-gray-300 text-gray-500"
          }`}
        >
          {capitalizedFirst(t("live-stream.voucher"))}
        </div>
        <div
          className="flex flex-1 items-center justify-end mr-[-20px]"
          onClick={gotoCart}
        >
          <i className="sctr-icon-shopping-cart-01 p-overlay-badge cursor-pointer text-[24px] text-gray-600">
            {countCart ? (
              <Badge
                style={{
                  minWidth: "20px",
                  width:"20px",
                  height:"20px",
                  fontSize: "8px",
                }}
                className="flex items-center justify-center p-0"
                severity="danger"
                value={countCart}
              ></Badge>
            ) : null}
          </i>
        </div>
      </div>
    </div>
  )

  const getVoucherData = () => {
    setStateView(STATE_VIEW.LOADING)
    new LivestreamCampaignControllerApi(configHeader(SERVICE.LIVESTREAM))
      .livestreamCampaignsLiveVouchersGet(id)
      .then((res) => {
        if (res?.data?.data?.length) {
          const result: DealVoucherType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            const voucherData = i?.voucher_discount_range[0] || undefined
            result.push({
              id: i?.id || "",
              voucherImg: i?.voucherType?.icon || "",
              reward: (voucherData?.discount_type === "PERCENTAGE" ? voucherData?.discount_percent : voucherData.discount_amount) || 0,
              voucherName: i?.name || "",
              minSpend: i?.voucher_discount_range[0]?.min_order_price,
              discountType: voucherData?.discount_type || "",
            })
          })
          if (result.length) {
            setVouchers(result)
            setStateView(STATE_VIEW.RENDER)
          }
        } else setStateView(STATE_VIEW.NOT_FOUND)
      })
      .catch(() => {
        setStateView(STATE_VIEW.NOT_FOUND)
      })
  }
  
  const getProductData = () => {
    setStateView(STATE_VIEW.LOADING)
    new LivestreamCampaignControllerApi(configHeader(SERVICE.LIVESTREAM))
      .livestreamCampaignsLiveProductsGet(id)
      .then((res) => {
        if (res?.data?.data) {
          const result: DealProductModalType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            if(i?.is_activated) {
              result.push({
                id: i?.id || "",
                productImg: i?.avatar?.id || "",
                productName: i?.name || "",
                price: i?.price_after_tax || 0,
                originPrice: i?.original_price_after_tax || 0,
                point: i?.point || 0,
                sold: i?.sold || 0,
                stockId: i?.stock_id || "",
                shopId: i?.object_id || "",
                productId: i?.id || "",
                liveToken: i?.lives_token || "",
                liveQuantity: i?.livestream_quantity || 0,
              })
            }
          })
          if (result.length) {
            setProduct(result)
            setStateView(STATE_VIEW.RENDER)
          }
        } else setStateView(STATE_VIEW.NOT_FOUND)
      })
      .catch(() => {
        setStateView(STATE_VIEW.NOT_FOUND)
      })
  }
  const updateClaimVoucher = (newVoucherId: string) => {
    const result: string[] = [...clampVouchers, newVoucherId]
    setClampVoucher(result)
  }

  const getClampVoucherData = () => {
    new ClaimVoucherControllerApi(configHeader(SERVICE.PROMOTION))
      .findByObjectId1("LIVESTREAM", "ONGOING")
      .then((res) => {
        if (res?.data?.data?.data?.length) {
          const result: string[] = []
          res.data.data.data.forEach((i) => {
            result.push(i?.voucher?.id || "")
          })
          if (result.length) setClampVoucher(result)
        }
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    getProductData()
  }, [])

  return (
    <>
      <Dialog
        id="storeDataModal"
        draggable={false}
        header={HeaderContent}
        visible={isShowModal}
        footer={FooterContent}
        onHide={() => handleClose()}
      >
        <div
          id="body_dialog"
          className="flex w-[924px] flex-col border-b border-gray-200 px-4 pb-4 "
        >
          <div className="flex min-h-[500px] w-full justify-center">
            {stateView === STATE_VIEW.LOADING ||
              stateView === STATE_VIEW.INIT ? (
                <div className="flex h-[500px] items-center">
                  <Loading />
                </div>
              ) : null
            }
            {stateView === STATE_VIEW.NOT_FOUND ? (
              <div className="flex h-[500px] items-center">
                <EmptyPage message={capitalizedFirst(t("global.data-not-found"))} />
              </div>
            ) : null}
            {stateView === STATE_VIEW.RENDER ? (
              <div className="mt-3 w-full">
                {tab === TAB_STATE.PRODUCT ? (
                  <div className="grid grid-cols-4 gap-4">
                    {products?.map((i) => (
                      <DealProductModal key={i.id} isShopOwner={isShopOwner} {...i} />
                    ))}
                  </div>
                ) : null}

                {tab === TAB_STATE.VOUCHER ? (
                  <div className="grid grid-cols-2 gap-3">
                    {vouchers?.map((i) => (
                      <DealVoucher
                        isShopOwner={isShopOwner}
                        isClamp={clampVouchers.includes(i.id)}
                        updateClaimData={(voucherId: string) =>
                          updateClaimVoucher(voucherId)
                        }
                        isRoom={false}
                        key={i.id}
                        {...i}
                      />
                    ))}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </Dialog>
    </>
  )
}
